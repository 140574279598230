<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    :sub-title-tbl="this.$t('Speed Boat')"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label=" this.$t('KeteranganTable') + '~'+ this.$t('Form Land Vehicle')+'~'"
    bottom-icon="GitMergeIcon"
    info-page-ability=""
    placeholder-search="Nama Lengkap"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'dm_speed_boat',
      titleTbl: 'DATA MASTER',
      fields: [
        // {
        //   minWidth: '130px',
        //   key: 'group.name',
        //   label: 'GROUP',
        //   placeholder: '',
        //   sortable: true,
        //   input: { type: 'input-noarea', disabled: true },
        //   searchModel: 'group.name',
        //   search: {
        //     type: 'text',
        //   },
        // },
        {
          key: 'speed_boat_name',
          label: 'Speed Boat Name',
          sortable: true,
          placeholder: 'ST CAKRA 9',
          input: { type: 'input-noarea' },
          searchModel: 'speed_boat_name',
          search: {
            type: 'text',
          },
        },
        {
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text',
          },
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number',
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
          searchModel: 'created_at',
          search: {
            type: 'date',
          },
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: 'date',
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
          searchModel: 'updated_at',
          search: {
            type: 'date',
          },
        },
      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        full_name: '',
        date_of_birth: moment(),
        no_ktp: '',
        phone: '',
        address: '',
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.dm_speed_boats
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
